import Vue from 'vue';
import { onBeforeMount, reactive, ref, watch } from '@vue/composition-api';
import {
  AppSubscriptionNumericSelectFieldType,
  AppSubscriptionNumericSelectFieldListType,
  AppSubscriptionListType,
  AppSubscriptionPaymentStatusEnum,
  AppSubscriptionsConfirmModalActionsEnum,
  AppSubscriptionsConfirmModalTitleEnum,
  AppSubscriptionsConfirmModalDescriptionEnum,
  AppSubscriptionsActionResponseMessageEnum,
  AppSubscriptionsFilterState,
  PartnerPaymentInsights,
  AppSubscriptionConfirmModalStateType,
} from '@/domains/finance-admin/types/appSubscriptions.type';
import { getAllAppsSubscriptions, updatePaymentStatus } from '@/domains/finance-admin/api/apps-subscriptions';
import AppSubscriptionStatusResponseModel from '@/domains/finance-admin/models/AppSubscriptionStatusResponseModel';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import ErrorModel from '@/api/models/ErrorModel';
import { useActions } from 'vuex-composition-helpers';

const useAppSubscriptions = () => {
  const pageSizeList = [];
  for (let index = 10; index <= 100; index += 10) {
    pageSizeList.push({
      label: `${index}`,
      value: `${index}`,
    });
  }
  const paymentStatusList = Object.values(AppSubscriptionPaymentStatusEnum).map((status) => {
    return { label: status, value: status };
  });
  const totalPages = ref(0);
  const bulkRefundable = ref(false);
  const bulkSettlementAllowed = ref(false);
  const isAllSelected = ref(false);
  const isSubscriptionsLoading = ref(false);
  const appSubscriptionsFilterState = reactive<AppSubscriptionsFilterState>({
    searchValue: '',
    selectedResultPerPage: {
      label: `10`,
      value: `10`,
    },
    selectedApp: {
      label: '',
      value: 0,
    },
    selectedPartner: {
      label: '',
      value: 0,
    },
    paymentStatus: {
      label: '',
      value: '',
    },
    dateRange: {
      startDate: '',
      endDate: '',
    },
    targetPage: 1,
  });
  const appsSubscriptions = reactive<AppSubscriptionListType>({ list: [] });
  const appsSubscriptionsSelected = reactive<AppSubscriptionListType>({ list: [] });
  const partnersList = reactive<AppSubscriptionNumericSelectFieldListType>({ list: [] });
  const applicationsList = reactive<AppSubscriptionNumericSelectFieldListType>({ list: [] });
  const insights = reactive<PartnerPaymentInsights>({
    totalPaidAmount: 0,
    pendingAmount: 0,
    settledAmount: 0,
    zidAmount: 0,
    refundedAmount: 0,
    partnerShare: 0,
  });
  const confirmModalState = reactive<AppSubscriptionConfirmModalStateType>({
    isOpen: false,
    isLoading: false,
    title: '',
    description: '',
    type: null,
    id: null,
    isBulk: false,
  });

  onBeforeMount(async () => {
    const { setIsAppLoading } = useActions(['setIsAppLoading']);
    setIsAppLoading(false);
    await loadAppSubscriptionsList();
  });

  watch(
    appsSubscriptionsSelected,
    () => {
      bulkRefundable.value = canBulkSettleOrRefund();
      bulkSettlementAllowed.value = canBulkSettleOrRefund();
      isAllSelected.value = isAllSelectedCheck();
    },
    { deep: true },
  );

  watch(appSubscriptionsFilterState, async () => await loadAppSubscriptionsList(), { deep: true });

  const loadAppSubscriptionsList = async () => {
    isSubscriptionsLoading.value = true;
    appsSubscriptionsSelected.list = [];
    appsSubscriptions.list = [];
    const params = {
      storeId: appSubscriptionsFilterState.searchValue,
      appId: appSubscriptionsFilterState.selectedApp.value,
      partnerId: appSubscriptionsFilterState.selectedPartner.value,
      payment_status: appSubscriptionsFilterState.paymentStatus.value,
      start_date: appSubscriptionsFilterState.dateRange.startDate,
      end_date: appSubscriptionsFilterState.dateRange.endDate,
      per_page: appSubscriptionsFilterState.selectedResultPerPage.value,
      page: appSubscriptionsFilterState.targetPage,
    };
    const response = await getAllAppsSubscriptions(params);
    if (!(<AppSubscriptionStatusResponseModel>response).payload || response instanceof ErrorModel) {
      Vue.$toast.error('Could not load subscriptions list');
      isSubscriptionsLoading.value = false;
      return;
    }
    const payload = (<AppSubscriptionStatusResponseModel>response).payload;
    appsSubscriptions.list = payload.subscriptionPaymentStatuses;
    partnersList.list = payload.partners.map((partner) => {
      return { label: partner.name, value: partner.id };
    });
    if (payload.selected_partner) {
      payload.applications = payload.applications.filter((application) => {
        return application.partner_id == payload.selected_partner?.id;
      });
    }
    applicationsList.list = payload.applications.map((application) => {
      return { value: application.id, label: application.name };
    });
    totalPages.value = payload.pagination.last_page;
    insights.totalPaidAmount = payload.total_paid_amount;
    insights.pendingAmount = payload.pending_amount;
    insights.settledAmount = payload.settled_amount;
    insights.refundedAmount = payload.refunded_amount;
    insights.zidAmount = payload.zid_amount;
    insights.partnerShare = payload.partner_share;
    isSubscriptionsLoading.value = false;
  };

  const onPartnerChange = (partner: AppSubscriptionNumericSelectFieldType) => {
    appSubscriptionsFilterState.selectedPartner = partner;
    appSubscriptionsFilterState.selectedApp = {
      value: 0,
      label: '',
    };
    resetTargetPage();
  };

  const onPaymentStatusChange = (status: Record<string, string>) => {
    appSubscriptionsFilterState.paymentStatus = status;
    resetTargetPage();
  };

  const onAppChange = (app: AppSubscriptionNumericSelectFieldType) => {
    appSubscriptionsFilterState.selectedApp = app;
    resetTargetPage();
  };

  const onDateValueChange = (changedDate: Record<string, string>, fieldName: string) => {
    let dateValue = changedDate.value;
    if (dateValue) {
      dateValue = dayjs(changedDate.value).format('YYYY-MM-DD');
    }
    appSubscriptionsFilterState.dateRange[fieldName] = dateValue;
    resetTargetPage();
  };

  const onResultPerPageChange = (perPage: Record<string, string>) => {
    appSubscriptionsFilterState.selectedResultPerPage = perPage;
    resetTargetPage();
  };

  const resetTargetPage = () => (appSubscriptionsFilterState.targetPage = 1);

  const resetConfirmModalState = () => {
    confirmModalState.isOpen = false;
    confirmModalState.isLoading = false;
    confirmModalState.title = '';
    confirmModalState.description = '';
    confirmModalState.type = null;
    confirmModalState.id = null;
    confirmModalState.isBulk = false;
  };

  const onSubscriptionItemAction = (id: string, action: AppSubscriptionsConfirmModalActionsEnum, isBulk: boolean) => {
    confirmModalState.isOpen = true;
    confirmModalState.type = action;
    confirmModalState.id = id;
    confirmModalState.isBulk = isBulk;
    confirmModalState.title =
      action === AppSubscriptionsConfirmModalActionsEnum.Settle
        ? AppSubscriptionsConfirmModalTitleEnum.Settle
        : AppSubscriptionsConfirmModalTitleEnum.Refund;
    confirmModalState.description = getConfirmModalDescription(action, isBulk);
  };

  const onRefundItemClick = (id: string, isBulk: boolean) => {
    onSubscriptionItemAction(id, AppSubscriptionsConfirmModalActionsEnum.Refund, isBulk);
  };

  const onSettleItemClick = (id: string, isBulk: boolean) => {
    onSubscriptionItemAction(id, AppSubscriptionsConfirmModalActionsEnum.Settle, isBulk);
  };

  const commitConfirmModalAction = async () => {
    confirmModalState.isLoading = true;
    if (confirmModalState.isBulk) {
      const canPerformAction = canBulkSettleOrRefund();
      if (!canPerformAction) {
        Vue.$toast.error(`This bulk action cannot be done on your current selection.`);
        resetConfirmModalState();
        return;
      }
    }
    const response = await updatePaymentStatus(confirmModalState.id, confirmModalState.type);
    if (response instanceof ErrorModel || response.status !== 'success') {
      Vue.$toast.error(`Couldn't complete your request!`);
      resetConfirmModalState();
      return;
    }
    const successMessage =
      confirmModalState.type === AppSubscriptionsConfirmModalActionsEnum.Settle
        ? AppSubscriptionsActionResponseMessageEnum.SettleSuccess
        : AppSubscriptionsActionResponseMessageEnum.RefundSuccess;
    await loadAppSubscriptionsList();
    resetConfirmModalState();
    Vue.$toast.success(successMessage);
  };

  const getSelectedIds = () => {
    return appsSubscriptionsSelected.list.map((subscription) => subscription.id).join(',');
  };

  const isSubscriptionRefundable = (status: AppSubscriptionPaymentStatusEnum) =>
    status == AppSubscriptionPaymentStatusEnum.Pending;

  const isSelectedSubscription = (id: number) => {
    return appsSubscriptionsSelected.list.map((subscription) => subscription.id).indexOf(id) !== -1;
  };

  const deselectSubscription = (id: number) => {
    appsSubscriptionsSelected.list = appsSubscriptionsSelected.list.filter((subscription) => {
      return subscription.id != id;
    });
  };

  const getSubscriptionById = (id: number) =>
    appsSubscriptions.list.find((subscription) => subscription.id === id) || null;

  const onCheckboxAction = (isChecked: boolean, id: number) => {
    if (!isChecked) {
      deselectSubscription(id);
      return;
    }
    if (isSelectedSubscription(id)) {
      return;
    }
    const subscription = getSubscriptionById(id);
    if (!subscription) {
      return;
    }
    appsSubscriptionsSelected.list.push(subscription);
  };

  const canBulkSettleOrRefund = () => {
    if (!appsSubscriptionsSelected.list.length) {
      return false;
    }
    return appsSubscriptionsSelected.list.every(
      (subscription) => subscription.payment_status === AppSubscriptionPaymentStatusEnum.Pending,
    );
  };

  const toggleSelectAll = (event: Record<any, any>) => {
    if (event.isChecked) {
      appsSubscriptionsSelected.list = appsSubscriptions.list;
      return;
    }
    appsSubscriptionsSelected.list = [];
  };

  const exportSubscriptions = () => {
    if (!appsSubscriptionsSelected.list.length) {
      Vue.$toast.error('No selected rows to export!');
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(appsSubscriptionsSelected.list);
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'subscriptions');
    XLSX.writeFile(new_workbook, `selected-subscriptions-payments-${dayjs().format('DD-MM-YYYY')}.xlsx`);
  };

  const isAllSelectedCheck = () => {
    return (
      appsSubscriptionsSelected.list.length > 0 &&
      appsSubscriptions.list.length === appsSubscriptionsSelected.list.length
    );
  };

  const getConfirmModalDescription = (action: AppSubscriptionsConfirmModalActionsEnum, isBulk: boolean) => {
    let description =
      action === AppSubscriptionsConfirmModalActionsEnum.Settle
        ? AppSubscriptionsConfirmModalDescriptionEnum.Settle
        : AppSubscriptionsConfirmModalDescriptionEnum.Refund;

    if (!isBulk) {
      return description;
    }

    description =
      action === AppSubscriptionsConfirmModalActionsEnum.Settle
        ? AppSubscriptionsConfirmModalDescriptionEnum.SettleBulk
        : AppSubscriptionsConfirmModalDescriptionEnum.RefundBulk;

    return description;
  };

  return {
    appsSubscriptions,
    partnersList,
    applicationsList,
    paymentStatusList,
    isSubscriptionsLoading,
    appsSubscriptionsSelected,
    pageSizeList,
    appSubscriptionsFilterState,
    totalPages,
    confirmModalState,
    AppSubscriptionsConfirmModalActionsEnum,
    AppSubscriptionPaymentStatusEnum,
    insights,
    bulkSettlementAllowed,
    bulkRefundable,
    isAllSelected,
    onPartnerChange,
    onPaymentStatusChange,
    onAppChange,
    onDateValueChange,
    onResultPerPageChange,
    resetTargetPage,
    resetConfirmModalState,
    isSubscriptionRefundable,
    commitConfirmModalAction,
    onRefundItemClick,
    onSettleItemClick,
    onCheckboxAction,
    isSelectedSubscription,
    toggleSelectAll,
    exportSubscriptions,
    getSelectedIds,
  };
};

export default useAppSubscriptions;
