import BaseModel from '@/api/models/BaseModel';
import {
  AppSubscriptionAppType,
  AppSubscriptionPartnerType,
  AppSubscriptionPaymentStatusEnum,
  AppSubscriptionType,
} from '@/domains/finance-admin/types/appSubscriptions.type';

class AppSubscriptionStatusResponseModel extends BaseModel {
  public payload: {
    subscriptionPaymentStatuses: AppSubscriptionType[];
    partners: AppSubscriptionPartnerType[];
    applications: AppSubscriptionAppType[];
    selected_partner: AppSubscriptionPartnerType | null;
    selected_application: AppSubscriptionAppType | null;
    selected_payment_status: null | AppSubscriptionPaymentStatusEnum;
    total_paid_amount: number;
    pending_amount: number;
    settled_amount: number;
    zid_amount: number;
    partner_share: number;
    refunded_amount: number;
    selected_store_url: string | null;
    datepicker_start_date: string | null;
    datepicker_end_date: string | null;
    pagination: {
      last_page: number;
      current_page: number;
    };
  };
  constructor(args: any) {
    super(args);
    this.payload = args.payload;
  }
}

export default AppSubscriptionStatusResponseModel;
