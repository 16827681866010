import { defineComponent } from '@vue/composition-api';

import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import ExportIcon from '@/assets/icons/domains/finance-admin/ExportIcon.vue';
import SettleIcon from '@/assets/icons/domains/finance-admin/SettleIcon.vue';
import RefundIcon from '@/assets/icons/domains/finance-admin/RefundIcon.vue';
import {
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidDatepicker,
  ZidButton,
  ZidCheckbox,
  ZidLoader,
  ZidTooltip,
  ZidPagination,
  ZidConfirmModal,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import AppSubscriptionInsightsCard from './components/AppSubscriptionInsightsCard.vue';

import useAppSubscriptions from '@/domains/finance-admin/helpers/useAppSubscriptions';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    SearchIcon,
    ExportIcon,
    SettleIcon,
    RefundIcon,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidDatepicker,
    ZidButton,
    ZidCheckbox,
    ZidLoader,
    ZidTooltip,
    ZidPagination,
    ZidConfirmModal,
    AppSubscriptionInsightsCard,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup() {
    return {
      ...useAppSubscriptions(),
    };
  },
});
