export enum AppSubscriptionPaymentStatusEnum {
  All = 'all',
  Pending = 'pending',
  Settled = 'settled',
  Refunded = 'refunded',
}

export enum AppSubscriptionRefundableStatusEnum {
  Refundable = 1,
  NotRefundable = 0,
}

export enum AppSubscriptionsConfirmModalActionsEnum {
  Refund = 'refunded',
  Settle = 'settled',
}

export enum AppSubscriptionsConfirmModalTitleEnum {
  Refund = 'Are you sure about making a refund?',
  Settle = 'Are you sure about making a settlement?',
}

export enum AppSubscriptionsConfirmModalDescriptionEnum {
  Refund = 'You are about to make a refund',
  Settle = 'You are about to make a settlement',
  RefundBulk = 'You are about to make a bulk refund to all the selected rows',
  SettleBulk = 'Your are about to make a bulk settlement to all the selected rows',
}

export enum AppSubscriptionsActionResponseMessageEnum {
  RefundSuccess = 'Your refund request was completed successfully',
  SettleSuccess = 'Your settlement request was completed successfully',
}

export type AppSubscriptionConfirmModalStateType = {
  isOpen: boolean;
  isLoading: boolean;
  title: string;
  description: string;
  type: AppSubscriptionsConfirmModalActionsEnum | null;
  id: string | null;
  isBulk: boolean;
};

export type AppSubscriptionType = {
  id: number;
  store_name: string;
  app_name: string;
  store_url: string;
  store_id: number;
  plan_name: string;
  partner_name: string;
  partner_percentage: number;
  zid_percentage: number;
  partner_share: number;
  zid_share: number;
  tax_paid: number;
  plan_price: number;
  total_amount_paid: number;
  subscription_status: string;
  payment_status: AppSubscriptionPaymentStatusEnum;
  invoice_type: string;
  is_refundable: AppSubscriptionRefundableStatusEnum;
  refund_amount: number;
  starts_at: string;
  expires_at: string;
};

export type AppSubscriptionListType = {
  list: AppSubscriptionType[];
};

export type AppSubscriptionNumericSelectFieldType = {
  label: string;
  value: number;
};

export type AppSubscriptionNumericSelectFieldListType = {
  list: AppSubscriptionNumericSelectFieldType[];
};

export type AppSubscriptionsFilterState = {
  searchValue: string;
  selectedResultPerPage: Record<string, string>;
  selectedApp: AppSubscriptionNumericSelectFieldType;
  selectedPartner: AppSubscriptionNumericSelectFieldType;
  paymentStatus: Record<string, string>;
  dateRange: Record<string, string>;
  targetPage: number;
};

export type AppSubscriptionsFilterParamsType = {
  storeId: string;
  appId: number;
  partnerId: number;
  payment_status: string;
  start_date: string;
  end_date: string;
  per_page: string;
  page: number;
};

export interface AppSubscriptionsFilterParamsTypeInterface {
  storeId?: string;
  appId?: number;
  partnerId?: number;
  payment_status?: string;
  start_date?: string;
  end_date?: string;
  per_page?: string;
  page?: number;
}
export enum AppSubscriptionsFilterParamsEnum {
  StoreId = 'storeId',
  AppId = 'appId',
  PartnerId = 'partnerId',
  PaymentStatus = 'payment_status',
  StartDate = 'start_date',
  EndDate = 'end_date',
  PerPage = 'per_page',
  Page = 'page',
}

export type AppSubscriptionPartnerType = {
  id: number;
  name: string;
  role: number;
};

export type AppSubscriptionAppType = {
  id: number;
  name: string;
  partner_id: number;
};

export interface PartnerPaymentInsights {
  totalPaidAmount: number;
  pendingAmount: number;
  settledAmount: number;
  zidAmount: number;
  refundedAmount: number;
  partnerShare: number;
}
