<template>
  <div class="py-7 px-4 rounded-xl bg-white-pureWhite">
    <base-text text-style="h4" classes="text-primary-700 lg:font-600">{{ title }}</base-text>
    <base-text text-style="h3" class="mt-3">
      {{ value }}
      SAR
    </base-text>
  </div>
</template>

<script>
import BaseText from '@/components/text/BaseText';
export default {
  name: 'AppSubscriptionInsightsCard',
  components: {
    BaseText,
  },
  props: {
    title: String,
    value: String,
  },
};
</script>
