import httpClient from '@/api/httpClient';
import AppSubscriptionStatusResponseModel from '@/domains/finance-admin/models/AppSubscriptionStatusResponseModel';
import ErrorModel from '@/api/models/ErrorModel';
import {
  AppSubscriptionsFilterParamsType,
  AppSubscriptionsFilterParamsTypeInterface,
  AppSubscriptionsConfirmModalActionsEnum,
} from '@/domains/finance-admin/types/appSubscriptions.type';
import BaseModel from '@/api/models/BaseModel';
const ENDPOINT_BASE_URL = '/market/finance-admin';

export const getAllAppsSubscriptions = (
  params: AppSubscriptionsFilterParamsType,
): Promise<AppSubscriptionStatusResponseModel | ErrorModel> => {
  const searchParams: AppSubscriptionsFilterParamsTypeInterface = {};
  if (params.storeId) {
    searchParams.storeId = params.storeId;
  }
  if (params.appId) {
    searchParams.appId = params.appId;
  }
  if (params.partnerId) {
    searchParams.partnerId = params.partnerId;
  }
  if (params.payment_status) {
    searchParams.payment_status = params.payment_status;
  }
  if (params.start_date) {
    searchParams.start_date = params.start_date;
  }
  if (params.end_date) {
    searchParams.end_date = params.end_date;
  }
  if (params.per_page) {
    searchParams.per_page = params.per_page;
  }
  if (params.page) {
    searchParams.page = params.page;
  }
  return httpClient
    .get(`${ENDPOINT_BASE_URL}/finance/payment-status`, { params: { ...searchParams } })
    .then((response) => new AppSubscriptionStatusResponseModel(response))
    .catch((error) => error);
};

export const updatePaymentStatus = (
  ids: string | null,
  status: AppSubscriptionsConfirmModalActionsEnum | null,
): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .put(`${ENDPOINT_BASE_URL}/finance/payment-status`, {
      paymentStatusIds: ids,
      paymentType: status,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
